import React, { Component} from 'react';
import { Spin } from 'antd';

class Loader extends Component
{
    render() {
        return <div className="loader"><Spin size="large" /></div>
    }
}


export default Loader