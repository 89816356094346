import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './views/views-dir.less';

Bugsnag.start({
    apiKey: '153661bbe487a39c166bfc652f5c3b98',
    plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)

ReactDOM.render( 
    <ErrorBoundary>
        <Router history={createBrowserHistory()}>
            <Route component={App}/>
        </Router>
    </ErrorBoundary>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();