import React, { Component } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import ProtectedRoute from './hoc/ProtectedRoute';
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import AuthService from "./services/api/AuthService";
import PublicRoute from "./hoc/PublicRoute";

interface IAppState {
  isLoading:Boolean
  errorVisible:Boolean
}
const authService = new AuthService();
const JoinUs:any = lazyComponentLoader(() => import('./views/join-us/JoinUsView'));

// DASHBOARD VIEW 
// const Dashboard:any = lazyComponentLoader(() => import('./views/dashboard/DashboardView'));


class App extends Component<any, IAppState> {
  
  constructor(props:any) {
    super(props);
    //initialize state here
    this.state = {
        isLoading: false,
        errorVisible: true
    }

    const self = this;
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      self.setState({isLoading: true});
      
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      self.setState({isLoading: false});
      
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({isLoading: false});
      return Promise.reject(error);
    });
  }

  showError(errors:any)
  {
    let content:any = null;
    if(isArray(errors))
    {
  
      // message.error(
        content = (< div>{
          errors.map( (item:any, index:number) => { return <div key={Math.random().toString(6)}>{item.message}</div>})
          }
        </div>)
    // );
    }else {
     content = errors.message.includes('Network Error') ? 'No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again.' : errors.message;
    }
    // const err = content? content.split('Error: ') : '';
    DialogUtils.error(content);
    
  }
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
              <PublicRoute
                exact
                path='/'
                component={JoinUs}
                auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              />
              <LayoutView>
                  {/* <ProtectedRoute exact path={['/', '/dashboard']} component={Dashboard} /> */}

                  {/* REDIRECT TO HOME/LOGIN IF NO PAGE FOUND */}
                  <Route render={(props:any) => 
                    {
                      const { key }  = props.location;
                      const isLoggedIn = authService.isLoggedIn();
                      if (isLoggedIn && !key) {
                        return <Redirect from='*' to="/" />
                      } else if (!isLoggedIn){
                        return <Redirect from='*' to="/" />
                      }
                    }
                  } />
                  {/* END */}
              </LayoutView>
            </Switch>
        </Router>
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;