import React, { useEffect, useState } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { Row, Col, Dropdown, Menu, Space } from 'antd';
import { UserOutlined
    } from '@ant-design/icons';
import { BurgerIcon, DropdownIcon } from '../../../../components/icons/Icons';


const HeaderView = (props:any) => {
    const [user, setUser]:any = useState();
    useEffect(() => {
        if (!localStorage.getItem('user')) {
            onLogout();
        } else {
            const data:any = localStorage.getItem('user');
            setUser(JSON.parse(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


   
    const onLogout = () => {
        localStorage.clear();
        props.history.push('/login');
    }

    const profile = () => {
        props.history.push('/profile');
    }

    const toggleSidebar = () => {
        props.collapseToggle(!props.siderCollapsed)
    }

    const menu = (
        <Menu>
            <Menu.Item key="0" className="link-secondary">
                <span onClick={() => profile()} className="c-pointer fw-600">
                    <UserOutlined /> My Profile
                </span>
            </Menu.Item>
            <Menu.Item key="2" className="link-secondary">
                    <span onClick={() => onLogout()} className="c-pointer fw-600">
                        <LogoutOutlined/> Log Out
                    </span>
            </Menu.Item>
        </Menu>
    );
   
    return (
        <Row justify="space-between" align="middle" className="padding-m">
            <Col>
                <BurgerIcon className="c-pointer" onClick={toggleSidebar} />
            </Col>
            <Col className="visible-xs"><h2 className="text-secondary r-margin mobile-header">{props.pageHeader}</h2></Col>
            <Col>
                <Dropdown overlay={menu} trigger={['click']} className="c-pointer hidden-xs">
                   <Row gutter={[10, 0]} align="middle">
                       <Col>
                            <div className="profile-img">
                                <UserOutlined /> {/* TEMPORARY FOR NOW */}
                            </div>
                       </Col>
                       <Col>
                            <Space>
                                <Space direction="vertical" size={0} className="fw-600 header-nav-settings">
                                    <span className="text-secondary">{user ? user.first_name : null}</span>
                                    <span className="text-gray text-capitalize">{user ? user.user_type : null}</span>
                                </Space>
                                <DropdownIcon />
                            </Space>
                       </Col>
                   </Row>
                </Dropdown>
            </Col>
        </Row>
    );
}

export default withRouter(HeaderView)