import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Menu, Row, Space } from 'antd';
import {useHistory, useLocation } from 'react-router-dom';
import logo from '../../../../assets/svg/logo-text.svg';
import logo_icon  from '../../../../assets/svg/logo.svg';
import { UserOutlined, CustomerServiceFilled, LogoutOutlined } from '@ant-design/icons';
// MachineIcon
import { BurgerIcon, CustomerIcon, DashboardIcon, DropdownIcon, ManageBatteriesIcon, ManageMachineIcon } from '../../../../components/icons/Icons';
import { BulbOutlined } from '@ant-design/icons';

const mobileProfileStyle = { left: 0,bottom: 10 }

const SideMenuView = ({siderCollapsed, collapseToggle}:any) => {   
    const location = useLocation();
    const history = useHistory();

    const [currentPath, setCurrentPath] = useState(location.pathname);
   
    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location.pathname]);

    const [user, setUser]:any = useState();
    useEffect(() => {
        if(!localStorage.getItem('user')) {
            onLogout();
        } else {
            const data:any = localStorage.getItem('user');
            setUser(JSON.parse(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLogout = () => {
        localStorage.clear();
        history.push('/login');
    }

    const profile = () => {
        history.push('/profile');
    }

   const onToggleMenus = (action:string) => {
        if(window.innerWidth <= 768) {
            collapseToggle(!siderCollapsed);
        }
        setCurrentPath(action);
        history.push(action);
   }

   const menu = (
        <Menu>
            <Menu.Item key="0" className="link-secondary">
                <span onClick={() => profile()} className="c-pointer fw-600">
                    <UserOutlined /> My Profile
                </span>
            </Menu.Item>
            <Menu.Item key="1" className="link-secondary">
                    <span onClick={(e) => e.preventDefault()} className="c-pointer fw-600">
                        <CustomerServiceFilled /> Help Center
                    </span>
            </Menu.Item>
            <Menu.Item key="2" className="link-secondary">
                    <span onClick={() => onLogout()} className="c-pointer fw-600">
                        <LogoutOutlined/> Log Out
                    </span>
            </Menu.Item>
        </Menu>
    );

   return (
        <Row>
            <Row align="middle" justify="center" style={{width: '100%',padding: '20px 0', paddingBottom: 10, overflow: 'hidden'}}>
                {!siderCollapsed ? (<Space align="center" style={{ height: 44}}>
                <img src={logo} alt="Viridi Parente" width="155" height="40" />
                <BurgerIcon className="c-pointer visible-xs" onClick={() => collapseToggle(!siderCollapsed)}/>
                </Space>) : 
                (<Space align="center" style={{ height: 44}}><img src={logo_icon} alt="Viridi Parente" width="80" height="40" /><BurgerIcon className="c-pointer  visible-xs" /></Space>)}
            </Row>
            <Menu  mode="inline" defaultSelectedKeys={[currentPath === '/' ? '/dashboard' : currentPath ]}>
                <Menu.Item  key="/dashboard" onClick={() => onToggleMenus('/dashboard')}>
                    <DashboardIcon/>
                    <span >Dashboard</span>
                </Menu.Item>
                <Menu.Item   key="/manage-machines" onClick={() => onToggleMenus('/manage-machines')}>
                    <ManageMachineIcon/>
                    <span  >Manage Machines</span>
                </Menu.Item>

                <Menu.Item  key="/manage-batteries" onClick={() => onToggleMenus('/manage-batteries')}>
                    <ManageBatteriesIcon />
                    <span >Manage Batteries</span>
                </Menu.Item>

                <Menu.Item  key="/manage-customers" onClick={() => onToggleMenus('/manage-customers')}>
                    <CustomerIcon />
                    <span >Manage Customers</span>
                </Menu.Item>

                <Menu.Item  key="/manage-tips" onClick={() => onToggleMenus('/manage-tips')}>
                    <BulbOutlined />
                    <span >Manage Tips</span>
                </Menu.Item>
            </Menu>
            <Row className="visible-xs max-width" style={{...mobileProfileStyle, position: 'absolute'}}>
                <Dropdown overlay={menu} trigger={['click']} className="c-pointer">
                    <Row gutter={[10, 0]} className="max-width" justify="center" align="middle">
                        <Col>
                            <div className="profile-img">
                                <UserOutlined /> {/* TEMPORARY FOR NOW */}
                            </div>
                        </Col>
                        <Col>
                            <Space>
                                <Space direction="vertical" size={0} className="fw-600 header-nav-settings">
                                    <span className="text-secondary">{user ? user.first_name : null}</span>
                                    <span className="text-gray text-capitalize">{user ? user.user_type : null}</span>
                                </Space>
                                <DropdownIcon />
                            </Space>
                        </Col>
                    </Row>
                </Dropdown>
            </Row>
            {!siderCollapsed ? (<div className="sidebar--overlay" onClick={() => collapseToggle(!siderCollapsed)}></div>) : null}
        </Row>
    );
}

export default SideMenuView