import APIService from "./APIService";



class AuthService extends APIService
{

   login = async ({email, password}:any) => {
      const result = await this.post({email, password}, 'auth/login');
        
      return result ? result.data : null;
   }

   forgotPassword = async ({email}:any) => {
      const result = await this.post({email}, 'auth/forgot-password');
        
      return result ? result.data : null;
   }

   resetPassword = async ({token, new_password, confirm_password}:any) => {
      const result = await this.post({token, new_password, confirm_password}, 'auth/reset-password');
        
      return result ? result.data : null;
   }

   isLoggedIn = () => {
      return localStorage.getItem('access_token') ? true : false;
   }

   joinUs = async ({company_name, size, industry, email, tmp}:any) => {
      const result = await this.post({company_name, size, industry, email, tmp}, 'early-access/join-us');
        
      return result ? result.data : null;
   }

   contactUs = async ({business_name, email, message, phone}:any) => {
    const result = await this.post({business_name, email, message, phone}, 'contact-us');
      
    return result ? result.data : null;
  }

   uploadImgSamp = () => {
        return this.baseURL + '/s3/upload-samp?tmp=';
    }

   test = async () => {
      const result = await this.get('test-api-call');

      return result ? result.data : null;
}


}

export default AuthService